$(document).ready(function () {
    /**
     * 渚ц竟瀵艰埅
     */
    var slideout = new Slideout({
        panel: document.getElementById('main'),
        menu: document.getElementById('sidebar'),
        padding: 256,
        tolerance: 70,
        easing: 'cubic-bezier(.32,2,.55,.27)',
        side: 'right',
        touch: false
    });

    $('#navbar-toggler').on('click', function () {
        slideout.toggle();
    });

    var header = document.getElementById('header');
    var menu = document.getElementById('menu');

    slideout.on('translate', function (translated) {
        header.style.transform = 'translateX(' + translated + 'px)';
        menu.style.transform = 'translateX(' + translated + 'px)';
    });

    slideout.on('beforeopen', function () {
        header.style.transition = 'transform 300ms ease';
        header.style.transform = 'translateX(-256px)';
        menu.style.transition = 'transform 300ms ease';
        menu.style.transform = 'translateX(-256px)';
    });

    slideout.on('beforeclose', function () {
        header.style.transition = 'transform 300ms ease';
        header.style.transform = 'translateX(0px)';
        menu.style.transition = 'transform 300ms ease';
        menu.style.transform = 'translateX(0px)';
    });

    slideout.on('open', function () {
        header.style.transition = '';
        menu.style.transition = '';
    });

    slideout.on('close', function () {
        header.style.transition = '';
        menu.style.transition = '';
    });

    /**
     * 杩斿洖椤堕儴
     */
    var main = $('.main');
    var scrollTotop = $('.scroll-to-top');
    if (main.length > 0 && scrollTotop.length > 0) {
        main.on('scroll', function () {
            if (main.scrollTop() > 300) {
                scrollTotop.fadeIn();
            } else {
                scrollTotop.fadeOut();
            }
        });
        scrollTotop.click(function () {
            main.animate({
                scrollTop: 0
            }, 500);
            return false;
        });
    }

    /**
     * 鐧惧害鍦板浘
     */
    var mapBox = $('#map-box');
    if (mapBox.length > 0) {
        var mapInfo = $.extend({
            lng: 87.586399, // 缁忓害
            lat: 43.875055, // 绾害
            title: '鏂扮枂鐧剧枂鍥剧綉缁滄湇鍔℃湁闄愬叕鍙?, // 鍏徃鍚嶇О
            message: '鍦板潃锛氫箤甯傛柊甯傚尯闀挎矙璺?58鍙风豢鑻戦泤绛?鍙锋ゼ4妤? // 鍦板潃
        }, window.MAP_INFO);

        // 鐧惧害鍦板浘API鍔熻兘
        var map = new BMap.Map("map-box");
        var point = new BMap.Point(mapInfo.lng, mapInfo.lat);
        var marker = new BMap.Marker(point); // 鍒涘缓鏍囨敞
        map.addOverlay(marker); // 灏嗘爣娉ㄦ坊鍔犲埌鍦板浘涓璡r
        map.centerAndZoom(point, 15);

        var opts = {
            width: 200, // 淇℃伅绐楀彛瀹藉害
            height: 100, // 淇℃伅绐楀彛楂樺害
            title: mapInfo.title, // 淇℃伅绐楀彛鏍囬
            enableMessage: true, //璁剧疆鍏佽淇℃伅绐楀彂閫佺煭鎭痋r
            message: mapInfo.message
        }
        var infoWindow = new BMap.InfoWindow(mapInfo.message, opts); // 鍒涘缓淇℃伅绐楀彛瀵硅薄 
        marker.addEventListener("click", function () {
            map.openInfoWindow(infoWindow, point); //寮€鍚俊鎭獥鍙r
        });

        var top_left_navigation = new BMap.NavigationControl(); //宸︿笂瑙掞紝娣诲姞榛樿缂╂斁骞崇Щ鎺т欢

        map.addControl(top_left_navigation);
    }

    /**
     * 鏃犻檺鍔犺浇
     */
    if ($('.pagination__next').length) {
        $('.infinite-list').infiniteScroll({
            // options
            elementScroll: '.main',
            path: '.pagination__next',
            append: '.list-item',
            history: false,
            status: '.page-load-status'
        });
    } else {
        $('.infinite-scroll-last').addClass('d-block');
    }

    // 棣栭〉澶у浘杞挱
    var indexBanner = new Swiper('.index-wap-banner', {
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    });


});